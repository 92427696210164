import { RiskStatus } from 'Types';
import { RISK_STATUSES, DEFAULT_RISK_STATUS } from 'Utils';

interface RiskStatusProps {
  riskStatus: RiskStatus;
  size?: number | string;
  fontSize?: number | string;
}

export default function RiskStatusIcon(props: RiskStatusProps) {
  const { riskStatus = DEFAULT_RISK_STATUS, size = 36, fontSize } = props;
  const statusColor = RISK_STATUSES[riskStatus].color;
  const style = { width: size, fontSize };

  return (
    <div className="d-flex position-relative" style={style}>
      <svg className="bi bi-square-fill" width="100%" fill="currentColor" color={statusColor} viewBox="0 0 16 16">
        <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2z" />
      </svg>
      <div className="position-absolute w-100 fw-bold text-white text-center text-middle">R</div>
    </div>
  );
}
