import { ControlStatusIcon, RiskStatusIcon, Tooltip } from 'Components';
import { ControlStatus, RiskStatus } from 'Types';
import { CONTROL_STATUSES, DEFAULT_CONTROL_STATUS, DEFAULT_RISK_STATUS, RISK_STATUSES } from 'Utils';

interface StatuesDisplayProps {
  controlStatus: ControlStatus;
  riskStatus: RiskStatus;
}

export default function StatusesDisplay({ controlStatus = DEFAULT_CONTROL_STATUS, riskStatus = DEFAULT_RISK_STATUS }: StatuesDisplayProps) {
  const controlStatusLabel = CONTROL_STATUSES[controlStatus].label;
  const riskStatusLabel = RISK_STATUSES[riskStatus].label;

  return (
    <div className="d-flex justify-content-center">
      <div className="hstack gap-1">
        <Tooltip tooltip={`Control Status - ${controlStatusLabel}`}>
          <ControlStatusIcon controlStatus={controlStatus} size={24} />
        </Tooltip>
        <Tooltip tooltip={`Risk Status - ${riskStatusLabel}`}>
          <RiskStatusIcon riskStatus={riskStatus} size={24} fontSize={16} />
        </Tooltip>
      </div>
    </div>
  );
}
