import { useCallback } from 'react';

import { useRequest } from '@objectit/utils';

export type RequestConfig = Parameters<typeof useRequest>[0];

export default function useApiRequest(config: RequestConfig) {
  const { requestState, response, error, pagination, sendRequest } = useRequest({ ...config });

  const sendApiRequest = useCallback(
    (payload: object = {}) => {
      let requestDetails;

      if (config.method === 'GET') {
        requestDetails = { params: payload };
      } else {
        requestDetails = { data: payload };
      }
      sendRequest(requestDetails);
    },
    [config.method, sendRequest]
  );

  const isSuccess = requestState === 'Success';
  const isError = requestState === 'Error';
  const isSent = isSuccess || isError;
  const isPending = !isSent && requestState !== 'idle';

  return {
    requestState,
    isPending,
    isSent,
    isSuccess,
    isError,
    response,
    error,
    pagination,
    sendRequest: sendApiRequest,
  };
}
