import { getAuthURI } from '@objectit/utils/dist/services/authService';
import { AuthSession, setAuthSession } from '@objectit/utils/dist/services/storageService';
import { ApiMiddleWareContext } from '@objectit/utils/dist/services/useRequest/middleware/ApiMiddleware';

import { useSelectedTenantId } from 'States';

import useApiRequest, { RequestConfig } from '../Api.services';

export function useTenantRequest({ url, ...config }: RequestConfig) {
  const tenantId = useSelectedTenantId();
  return useApiRequest({ ...config, url: `tenants/${tenantId}${url}` });
}

export function useFetchTagList() {
  return useApiRequest({ url: 'tenants/tags', method: 'GET', onMount: true, params: { group: 'control' } });
}

export function useGetStatusCategories() {
  return useApiRequest({ url: 'tenants/status_categories', method: 'GET' });
}

export function useUpdateStatusCategories() {
  return useApiRequest({ url: 'tenants/status_categories/update_categories', method: 'PUT' });
}

export function useRefreshTokenRequest() {
  const { refreshTokenUri } = getAuthURI();

  function onSuccess({ response }: ApiMiddleWareContext) {
    setAuthSession(response as AuthSession);
  }

  return useApiRequest({
    method: 'POST',
    url: refreshTokenUri,
    onSuccess,
  });
}
